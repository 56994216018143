<template>
  <v-container
    fluid
  >

    <v-flex d-flex>
      <v-layout wrap>
        <v-flex 
          md4 
          v-for="course in courses"
          :key="course.courseid"
          pa-2         
        >
          <courseCard 
            :course="course"
            @deleteCourse="deleteCourse"
            @duplicateCourse="duplicateCourse"
            @renameCourse="renameCourse"
          ></courseCard>
        </v-flex>
      </v-layout>
    </v-flex>

  </v-container>
</template>

<script>
  import errorUtils from '@/utils/errorUtils'
  import { RepositoryFactory } from '@/repositories/RepositoryFactory'

  const CourseRepository = RepositoryFactory.get('course');

  export default {
    name: 'MyCourses',

    components: {
      courseCard: () => import("@/components/CourseCard"),
    },

    data: () => ({
      courses: [],
    }),

    mounted() {
      this.loadCourses();
    },

    methods: {

      async deleteCourse(courseId) {
        const { data } = await CourseRepository.deleteCourse(courseId);
        if (data.success) {               
          this.loadCourses();
        } else {
          errorUtils.showError(data.message);
        }
      },

      async duplicateCourse(courseId) {
        const { data } = await CourseRepository.duplicateCourse(courseId);
        if (data.success) {               
          this.loadCourses();
        } else {
          errorUtils.showError(data.message);
        }
      },

      async loadCourses() {
        const { data } = await CourseRepository.listMyCourses();
        if (data.success) {               
          this.courses = data.data.courses;
        } else {
          errorUtils.showError(data.message);
        }
      },

      async renameCourse(courseId, title) {        
        const { data } = await CourseRepository.renameCourse(courseId, title);
        if (data.success) {               
          this.loadCourses();
        } else {
          errorUtils.showError(data.message);
        }
      },

    },

  }
</script>
